@keyframes pizza-wave { 
    0% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

@keyframes egg-left-wave { 
    0% {
        transform: rotate(5deg);
    }
    25% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}

@keyframes egg-right-wave { 
    0% {
        transform: rotate(-5deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

@keyframes egg-tear { 
    0% {
        transform: translateY(0) scale(0);
        opacity: 0;
    }
    50% {
        transform: translateY(10px) scale(1);
        opacity: 1;
    }
    100% {
        transform: translateY(25px);
        opacity: 0;
    }
}

@keyframes orange-roll { 
    0% {
        transform: translateX(0) rotate(0);
    }
    50% {
        transform: translateX(-20px) rotate(-15deg);
    }
    100% {
        transform: translateX(0) rotate(0);
    }
}

@keyframes orange-shadow { 
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(-20px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes bottle-leg-left { 
    0% {
        transform: rotate(-5deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
    75% {
        transform: rotate(5deg);
    }
    100% {
        transform: rotate(-5deg);
    }
}

@keyframes bottle-leg-right { 
    0% {
        transform: rotate(5deg);
    }
    25% {
        transform: rotate(-5deg);
    }
    50% {
        transform: rotate(5deg);
    }
    75% {
        transform: rotate(-5deg);
    }
    100% {
        transform: rotate(5deg);
    }
}

@keyframes bowl { 
    0% {
        transform: translate(-50%,-120px);
    }
    100% {
        transform: translate(-50%,0);
        opacity: 1;
    }
}

@keyframes shadow {
    50% {
        width: 0;
        transform: translateX(-50%);
    }
    100% {
        width: 120px;
        transform: translateX(-50%);
        opacity: 1;
    }
}

@mixin bowl {
    animation-name: bowl;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@mixin shadow {
    animation-name: shadow;
    animation-duration: 0.6s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

.bowl.b1.go {
    @include bowl;
}

.s1.go {
    @include shadow;
}
.bowl.b2.go {
    @include bowl;
    animation-delay: 0.15s;
}

.s2.go {
    @include shadow;
    animation-delay: 0.15s;
}
.bowl.b3.go {
    @include bowl;
    animation-delay: 0.3s;
}

.s3.go {
    @include shadow;
    animation-delay: 0.3s;
}
.bowl.b4.go {
    @include bowl;
    animation-delay: 0.45s;
}

.s4.go {
    @include shadow;
    animation-delay: 0.45s;
}
.bowl.b5.go {
    @include bowl;
    animation-delay: 0.6s;
}

.s5.go {
    @include shadow;
    animation-delay: 0.6s;
}
.bowl.b6.go {
    @include bowl;
    animation-delay: 0.75s;
}

.s6.go {
    @include shadow;
    animation-delay: 0.75s;
}
.bowl.b7.go {
    @include bowl;
    animation-delay: 0.9s;
}

.s7.go {
    @include shadow;
    animation-delay: 0.9s;
}

.kcal {
    animation-name: kcal;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

@keyframes kcal {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.kcal-shadow {
    animation-name: kcal-shadow;
    animation-duration: 0.6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 1.5s;
}

@keyframes kcal-shadow {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes element {
    0% {
        opacity: 0;
        transform: translate(-50%,-50%) scale(0);
    }
    100% {
        opacity: 1;
        transform: translate(-50%,-50%) scale(1);
    }
}

@mixin element {
    animation-name: element;
    animation-duration: 0.7s;
    animation-timing-function: cubic-bezier(0.195, -0.015, 0.000, 1.295);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
}

.element.carbs.go {
    @include element;
}
.element.proteins.go {
    @include element;
}
.element.fruits.go {
    @include element;
}
.element.veggies.go {
    @include element;
}
.element.drinks.go {
    @include element;
}

@keyframes element-shadow {
    0% {
        opacity: 0;
        transform: translateX(-50%) scale(0);
    }
    100% {
        opacity: 1;
        width: 80px;
        transform: translateX(-50%) scale(1);
    }
}

.nutrient .shadow.go {
    animation-name: element-shadow;
    animation-duration: 0.7s;
    animation-timing-function: cubic-bezier(0.195, -0.015, 0.000, 1.295);
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
}

@keyframes arrow {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.arrow.go {
    animation-name: arrow;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-delay: 0.3s;
}

@keyframes pulse {
    0% {
        transform: scale(0.7);
        opacity: 0.4;       
    }
    50% {
        transform: scale(1);
        opacity: 1; 
    }   
    100% {
        transform: scale(0.7);
        opacity: 0.4;   
    }           
}