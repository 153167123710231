@keyframes pizza-wave {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes egg-left-wave {
  0% {
    transform: rotate(5deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes egg-right-wave {
  0% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes egg-tear {
  0% {
    transform: translateY(0) scale(0);
    opacity: 0;
  }
  50% {
    transform: translateY(10px) scale(1);
    opacity: 1;
  }
  100% {
    transform: translateY(25px);
    opacity: 0;
  }
}

@keyframes orange-roll {
  0% {
    transform: translateX(0) rotate(0);
  }
  50% {
    transform: translateX(-20px) rotate(-15deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
}

@keyframes orange-shadow {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes bottle-leg-left {
  0% {
    transform: rotate(-5deg);
  }
  25% {
    transform: rotate(5deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  75% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(-5deg);
  }
}

@keyframes bottle-leg-right {
  0% {
    transform: rotate(5deg);
  }
  25% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
  75% {
    transform: rotate(-5deg);
  }
  100% {
    transform: rotate(5deg);
  }
}

@keyframes bowl {
  0% {
    transform: translate(-50%, -120px);
  }
  100% {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes shadow {
  50% {
    width: 0;
    transform: translateX(-50%);
  }
  100% {
    width: 120px;
    transform: translateX(-50%);
    opacity: 1;
  }
}

.bowl.b1.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.s1.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.bowl.b2.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
}

.s2.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.15s;
}

.bowl.b3.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.s3.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.bowl.b4.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.45s;
}

.s4.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.45s;
}

.bowl.b5.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.s5.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.6s;
}

.bowl.b6.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.75s;
}

.s6.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.75s;
}

.bowl.b7.go {
  animation-name: bowl;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}

.s7.go {
  animation-name: shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.9s;
}

.kcal {
  animation-name: kcal;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

@keyframes kcal {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.kcal-shadow {
  animation-name: kcal-shadow;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
}

@keyframes kcal-shadow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes element {
  0% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0);
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.element.carbs.go {
  animation-name: element;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.195, -0.015, 0, 1.295);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.element.proteins.go {
  animation-name: element;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.195, -0.015, 0, 1.295);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.element.fruits.go {
  animation-name: element;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.195, -0.015, 0, 1.295);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.element.veggies.go {
  animation-name: element;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.195, -0.015, 0, 1.295);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

.element.drinks.go {
  animation-name: element;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.195, -0.015, 0, 1.295);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

@keyframes element-shadow {
  0% {
    opacity: 0;
    transform: translateX(-50%) scale(0);
  }
  100% {
    opacity: 1;
    width: 80px;
    transform: translateX(-50%) scale(1);
  }
}

.nutrient .shadow.go {
  animation-name: element-shadow;
  animation-duration: 0.7s;
  animation-timing-function: cubic-bezier(0.195, -0.015, 0, 1.295);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

@keyframes arrow {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.arrow.go {
  animation-name: arrow;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

@keyframes pulse {
  0% {
    transform: scale(0.7);
    opacity: 0.4;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.7);
    opacity: 0.4;
  }
}

.group:after {
  content: "";
  display: table;
  clear: both;
}

body {
  padding: 0;
  margin: 0;
  background-color: #e74948;
}

#intro {
  position: relative;
  width: 100%;
  height: 100vh;
}

#intro #fridge-top {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 960px;
  height: 660px;
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  background-image: url("../img/fridge-top-shadow.png");
  background-position: bottom;
  background-color: #ffffff;
}

#intro #fridge-top .name {
  position: absolute;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #f1efea;
  letter-spacing: 0.045em;
  z-index: 2;
}

#intro #fridge-top .indents {
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

#intro #fridge-top .handle {
  position: absolute;
  bottom: 50px;
  left: 60px;
}

#intro #fridge-top .info {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#intro #fridge-top .info .title {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #4a4a4a;
  letter-spacing: 0.045em;
  margin-bottom: 10px;
}

#intro #fridge-top .info .text {
  position: relative;
  max-width: 350px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #4a4a4a;
  letter-spacing: 0.045em;
}

#intro #fridge-top .button-go {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 44px;
  padding: 0 35px;
  background-color: #ff555b;
  border-radius: 3px;
  line-height: 44px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  cursor: pointer;
}

@media screen and (max-height: 720px) {
  #fridge-top {
    height: 560px !important;
  }
}

#fridge-middle {
  position: relative;
  width: 100%;
}

#fridge-inside {
  position: relative;
  width: 960px;
  margin: 0 auto;
  background-color: #e6e2d0;
  padding: 60px 0;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
}

#fridge-inside .top-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 30px;
}

#fridge-inside .outer-shadow {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-image: url("../img/fridge-outer-shadow.png");
  background-position: bottom;
}

#fridge-inside .outer-shadow .bottom {
  position: absolute;
  bottom: -4px;
  left: 0;
  z-index: 2;
}

#inside-big {
  position: relative;
  margin: 0 auto;
  padding: 120px 0;
  width: 840px;
  background-color: #f7f5e6;
  border-radius: 30px;
  box-shadow: inset 0px 0px 50px 0px #f0edd5;
}

#inside-big .inside-small {
  position: absolute;
  top: 80px;
  left: 80px;
  width: 680px;
  height: calc( 100% - 160px);
  border-radius: 30px;
  background-color: #f7f6ed;
  text-align: center;
}

.title-big {
  position: relative;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 48px;
  color: #4a4a4a;
  letter-spacing: 0.045em;
  text-align: center;
  margin: 120px 0;
}

.no-margin-top {
  margin-top: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.tnx-margin {
  margin-top: 160px;
  margin-bottom: 60px;
}

#shelf-team1 {
  position: relative;
  width: 100%;
  height: 300px;
  margin-bottom: 70px;
}

#shelf-team1 .shelf1 {
  position: absolute;
  bottom: 0;
  left: 20px;
}

#shelf-team1 .teammate1 {
  position: absolute;
  bottom: 44px;
  left: 100px;
  width: 160px;
  height: 262px;
}

#shelf-team1 .teammate1 .shadow {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 54px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
}

#shelf-team1 .teammate1 .pizza {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  cursor: pointer;
}

#shelf-team1 .teammate1 .pizza:hover + .label {
  opacity: 1;
}

#shelf-team1 .teammate1 .pizza-arm {
  position: absolute;
  bottom: 87px;
  left: 12px;
  transform-origin: 33px 22px;
  animation-name: pizza-wave;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#shelf-team1 .teammate1 .label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 44px;
  padding: 0 20px;
  background-color: #ff595a;
  border-radius: 3px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 44px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

#shelf-team1 .teammate1 .label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  /* left arrow slant */
  border-right: 8px solid transparent;
  /* right arrow slant */
  border-top: 10px solid #ff595a;
  /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
}

#shelf-team1 .teammate2 {
  position: absolute;
  bottom: 44px;
  right: 300px;
  width: 154px;
  height: 262px;
}

#shelf-team1 .teammate2:hover .label {
  opacity: 1;
}

#shelf-team1 .teammate2 .shadow {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 130px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
}

#shelf-team1 .teammate2 .egg {
  position: absolute;
  bottom: 0;
  left: 20px;
  z-index: 2;
  cursor: pointer;
}

#shelf-team1 .teammate2 .egg:hover + .label {
  opacity: 1;
}

#shelf-team1 .teammate2 .egg-arm-left {
  position: absolute;
  bottom: 60px;
  left: 0;
  transform-origin: 33px 22px;
  animation-name: egg-left-wave;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#shelf-team1 .teammate2 .egg-arm-right {
  position: absolute;
  bottom: 57px;
  right: 0;
  transform-origin: 2px 28px;
  animation-name: egg-right-wave;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  z-index: 3;
}

#shelf-team1 .teammate2 .egg-tear {
  position: absolute;
  bottom: 62px;
  right: 64px;
  animation-name: egg-tear;
  animation-duration: 1.6s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  z-index: 3;
}

#shelf-team1 .teammate2 .label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 44px;
  padding: 0 20px;
  background-color: #ffd655;
  border-radius: 3px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 44px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

#shelf-team1 .teammate2 .label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  /* left arrow slant */
  border-right: 8px solid transparent;
  /* right arrow slant */
  border-top: 10px solid #ffd655;
  /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
}

#shelf-team1 .teammate3 {
  position: absolute;
  bottom: 44px;
  right: -48px;
  width: 188px;
  height: 262px;
}

#shelf-team1 .teammate3 .shadow {
  position: absolute;
  bottom: 4px;
  left: 24px;
  width: 90px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
  animation-name: orange-shadow;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#shelf-team1 .teammate3 .orange {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
  cursor: pointer;
  animation-name: orange-roll;
  animation-duration: 2.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  cursor: pointer;
}

#shelf-team1 .teammate3 .orange:hover + .label {
  opacity: 1;
}

#shelf-team1 .teammate3 .label {
  position: absolute;
  top: 0;
  left: calc( 50% - 50px);
  transform: translateX(-50%);
  height: 44px;
  padding: 0 30px;
  background-color: #ffa345;
  border-radius: 3px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 44px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

#shelf-team1 .teammate3 .label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 12px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  /* left arrow slant */
  border-right: 8px solid transparent;
  /* right arrow slant */
  border-top: 10px solid #ffa345;
  /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
}

#shelf-team2 {
  position: relative;
  width: 100%;
  height: 300px;
}

#shelf-team2 .shelf1 {
  position: absolute;
  bottom: 0;
  left: 20px;
}

#shelf-team2 .teammate1 {
  position: absolute;
  bottom: 44px;
  left: 100px;
  width: 204px;
  height: 285px;
}

#shelf-team2 .teammate1 .shadow {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
}

#shelf-team2 .teammate1 .cabbage {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  cursor: pointer;
}

#shelf-team2 .teammate1 .cabbage:hover + .label {
  opacity: 1;
}

#shelf-team2 .teammate1 .label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 44px;
  padding: 0 20px;
  background-color: #afda6e;
  border-radius: 3px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 44px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

#shelf-team2 .teammate1 .label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  /* left arrow slant */
  border-right: 8px solid transparent;
  /* right arrow slant */
  border-top: 10px solid #afda6e;
  /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
}

#shelf-team2 .teammate2 {
  position: absolute;
  bottom: 44px;
  right: 40px;
  width: 432px;
  height: 285px;
}

#shelf-team2 .teammate2:hover .label {
  opacity: 1;
}

#shelf-team2 .teammate2 .shadow {
  position: absolute;
  bottom: 4px;
  left: 0;
  width: 420px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
}

#shelf-team2 .teammate2 .bottle {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;
  cursor: pointer;
}

#shelf-team2 .teammate2 .bottle:hover + .label {
  opacity: 1;
}

#shelf-team2 .teammate2 .bottle-leg-left {
  position: absolute;
  bottom: 41px;
  right: 0;
  transform-origin: 2px 12px;
  animation-name: bottle-leg-left;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  z-index: 3;
}

#shelf-team2 .teammate2 .bottle-leg-right {
  position: absolute;
  bottom: 56px;
  right: 0;
  transform-origin: 1px 12px;
  animation-name: bottle-leg-right;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#shelf-team2 .teammate2 .cup {
  position: absolute;
  bottom: 15px;
  right: 62px;
  z-index: 2;
  cursor: pointer;
}

#shelf-team2 .teammate2 .cup:hover + .label {
  opacity: 1;
}

#shelf-team2 .teammate2 .label {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 44px;
  padding: 0 20px;
  background-color: #8ed8f6;
  border-radius: 3px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 44px;
  opacity: 0;
  transition: 0.3s ease-in-out;
}

#shelf-team2 .teammate2 .label::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  /* left arrow slant */
  border-right: 8px solid transparent;
  /* right arrow slant */
  border-top: 10px solid #8ed8f6;
  /* bottom, add background color here */
  font-size: 0;
  line-height: 0;
}

.title-small {
  position: relative;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #4a4a4a;
  letter-spacing: 0.025em;
  text-align: center;
  margin-bottom: 100px;
}

.shelf-bowls {
  position: relative;
  width: 100%;
  height: 180px;
  margin-bottom: 90px;
}

.shelf-bowls .shelf1 {
  position: absolute;
  bottom: 0;
  left: 20px;
}

.shelf-bowls .bowls-container {
  position: absolute;
  bottom: 44px;
  left: 30px;
  width: 780px;
  height: 150px;
  padding: 0 10px;
}

.shelf-bowls .bowls-container .bowl-element {
  position: relative;
  width: 170px;
  height: 100%;
  margin: 0 10px;
  float: left;
}

.shelf-bowls .bowls-container .bowl-element .bowl {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 0;
}

.shelf-bowls .bowls-container .bowl-element .shadow {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
  opacity: 0;
}

.shelf-bowls .bowls-container .bowl-element .count {
  position: absolute;
  bottom: 28px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: #4a4a4a;
  letter-spacing: 0.025em;
  z-index: 3;
}

.shelf-bowls .bowls-container .bowl-element .kcal {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;
  background-color: #afda6e;
  border-radius: 50%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 60px;
  z-index: 2;
  opacity: 0;
}

.shelf-bowls .bowls-container .bowl-element .kcal-shadow {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
  opacity: 0;
}

.margin-bottom-140 {
  margin-bottom: 140px;
}

#table {
  position: relative;
  width: 100%;
  height: 600px;
  margin-bottom: 140px;
}

#table .shelf-top {
  position: absolute;
  top: 0;
  left: 20px;
  width: 800px;
  height: 144px;
  z-index: 10;
}

#table .shelf-top .shelf1 {
  position: absolute;
  bottom: 0;
  max-height: 94px;
}

#table .shelf-top .nutrients {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 600px;
}

#table .shelf-top .nutrients .nutrient {
  position: relative;
  width: 100px;
  height: 100px;
  margin: 0 10px;
  float: left;
}

#table .shelf-top .nutrients .nutrient .shadow {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
  opacity: 0;
}

#table .shelf-top .nutrients .nutrient .element {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
  line-height: 100px;
  z-index: 2;
}

#table .shelf-top .nutrients .nutrient .carbs {
  background-color: #ff595a;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}

#table .shelf-top .nutrients .nutrient .proteins {
  background-color: #ffd655;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}

#table .shelf-top .nutrients .nutrient .fruits {
  background-color: #ffa345;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}

#table .shelf-top .nutrients .nutrient .veggies {
  background-color: #afda6e;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}

#table .shelf-top .nutrients .nutrient .drinks {
  background-color: #8ed8f6;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}

#table .shelf-bottom {
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 800px;
  max-height: 94px;
  z-index: 1;
}

#table .pole-left {
  position: absolute;
  bottom: 44px;
  left: 70px;
}

#table .pole-right {
  position: absolute;
  bottom: 44px;
  right: 70px;
}

#table .days {
  position: absolute;
  bottom: 42px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 600px;
}

#table .days .day {
  height: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #bccfce;
  letter-spacing: 0.045em;
  line-height: 20px;
  float: left;
}

#table .days .mon {
  width: 90px;
}

#table .days .tue {
  width: 70px;
}

#table .days .wed {
  width: 100px;
}

#table .days .thu {
  width: 70px;
}

#table .days .fri {
  width: 100px;
}

#table .days .sat {
  width: 80px;
}

#table .days .sun {
  width: 90px;
}

#table #table-base {
  position: absolute;
  top: 144px;
  left: -60px;
  width: 960px;
  height: 360px;
}

#table #table-base .grams {
  position: relative;
  width: 130px;
  height: 100%;
  float: left;
}

#table #table-base .grams .title {
  position: relative;
  width: 60px;
  height: 100%;
  float: left;
}

#table #table-base .grams .title .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #bccfce;
  letter-spacing: 0.045em;
}

#table #table-base .grams .marks {
  position: relative;
  width: 70px;
  height: 100%;
  padding: 30px 0;
  float: left;
}

#table #table-base .grams .marks ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

#table #table-base .grams .marks ul li {
  width: 100%;
  height: 60px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #bccfce;
  letter-spacing: 0.045em;
  line-height: 60px;
  list-style: none;
}

#table #table-base .liters {
  position: relative;
  width: 130px;
  height: 100%;
  float: left;
}

#table #table-base .liters .title {
  position: relative;
  width: 60px;
  height: 100%;
  float: left;
}

#table #table-base .liters .title .text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.045em;
}

#table #table-base .liters .marks {
  position: relative;
  width: 70px;
  height: 100%;
  padding: 30px 0;
  float: left;
}

#table #table-base .liters .marks ul {
  width: 100%;
  margin: 0;
  padding: 0;
}

#table #table-base .liters .marks ul li {
  width: 100%;
  height: 60px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  color: #bccfce;
  letter-spacing: 0.045em;
  line-height: 60px;
  list-style: none;
}

#table #table-base .table {
  position: relative;
  width: 668px;
  height: 100%;
  margin: 0 16px;
  float: left;
}

#table #table-base .table .row {
  position: relative;
  width: calc( 100% - 80px);
  height: 16.3%;
  border-bottom: 2px solid #dfeeea;
  padding: 0 40px;
}

#table #table-base .table .row .field {
  position: relative;
  width: 84px;
  height: 100%;
  float: left;
}

#table #table-base .table .row .field .line-ver {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 100%;
  background-color: #dfeeea;
}

#table #table-base .table .row .a1 {
  position: relative;
}

#table #table-base .table .row .a1 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 3px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .a1 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .a1 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .a1 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 29px;
  height: 29px;
  background-color: #ffa345;
}

#table #table-base .table .row .a1 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .a1 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .b1 {
  position: relative;
}

#table #table-base .table .row .b1 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 3px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .b1 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .b1 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .b1 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 29px;
  height: 29px;
  background-color: #ffa345;
}

#table #table-base .table .row .b1 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .b1 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .b1 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 50;
}

#table #table-base .table .row .b1 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .b1 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .b1 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 25px;
  height: 25px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .b1 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .b1 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .c1 {
  position: relative;
}

#table #table-base .table .row .c1 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: 3px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .c1 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .c1 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .c1 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 26px;
  height: 26px;
  background-color: #ffa345;
}

#table #table-base .table .row .c1 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .c1 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .c1 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 5px;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .c1 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .c1 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .c1 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 28px;
  height: 28px;
  background-color: #ff595a;
}

#table #table-base .table .row .c1 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .c1 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .d1 {
  position: relative;
}

#table #table-base .table .row .d1 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .d1 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .d1 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .d1 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 25px;
  height: 25px;
  background-color: #ffa345;
}

#table #table-base .table .row .d1 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .d1 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .e1 {
  position: relative;
}

#table #table-base .table .row .e1 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

#table #table-base .table .row .e1 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .e1 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .e1 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 27px;
  height: 27px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .e1 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .e1 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .g1 {
  position: relative;
}

#table #table-base .table .row .g1 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 50;
}

#table #table-base .table .row .g1 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .g1 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .g1 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 25px;
  height: 25px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .g1 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .g1 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .a2 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 50;
}

#table #table-base .table .row .a2 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .a2 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .a2 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 20px;
  height: 20px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .a2 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .a2 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .c2 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 50;
}

#table #table-base .table .row .c2 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .c2 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .c2 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 20px;
  height: 20px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .c2 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .c2 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .c2 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 6px;
  transform: translateX(-50%);
  z-index: 40;
}

#table #table-base .table .row .c2 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .c2 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .c2 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 23px;
  height: 23px;
  background-color: #afda6e;
}

#table #table-base .table .row .c2 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .c2 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .e2 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: -3px;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .e2 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .e2 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .e2 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 24px;
  height: 24px;
  background-color: #ff595a;
}

#table #table-base .table .row .e2 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .e2 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .f2 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: -13px;
  transform: translateX(-50%);
  z-index: 50;
}

#table #table-base .table .row .f2 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .f2 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .f2 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 24px;
  height: 24px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .f2 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .f2 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .g2 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 0;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .g2 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .g2 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .g2 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 24px;
  height: 24px;
  background-color: #ff595a;
}

#table #table-base .table .row .g2 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .g2 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .g2 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .g2 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .g2 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .g2 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 20px;
  height: 20px;
  background-color: #ffa345;
}

#table #table-base .table .row .g2 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .g2 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .a3 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .a3 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .a3 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .a3 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 16px;
  height: 16px;
  background-color: #ff595a;
}

#table #table-base .table .row .a3 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .a3 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .b3 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 6px;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .b3 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .b3 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .b3 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 18px;
  height: 18px;
  background-color: #ff595a;
}

#table #table-base .table .row .b3 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .b3 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .b3 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: -2px;
  transform: translateX(-50%);
  z-index: 40;
}

#table #table-base .table .row .b3 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .b3 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .b3 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 18px;
  height: 18px;
  background-color: #afda6e;
}

#table #table-base .table .row .b3 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .b3 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .d3 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 8px;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .d3 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .d3 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .d3 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 18px;
  height: 18px;
  background-color: #ff595a;
}

#table #table-base .table .row .d3 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .d3 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .d3 .drinks {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 50;
}

#table #table-base .table .row .d3 .drinks:hover {
  z-index: 60;
}

#table #table-base .table .row .d3 .drinks:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .d3 .drinks .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 18px;
  height: 18px;
  background-color: #8ed8f6;
}

#table #table-base .table .row .d3 .drinks .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .d3 .drinks .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .d3 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -16px;
  transform: translateX(-50%);
  z-index: 40;
}

#table #table-base .table .row .d3 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .d3 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .d3 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 15px;
  height: 15px;
  background-color: #afda6e;
}

#table #table-base .table .row .d3 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .d3 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .e3 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -6px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .e3 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .e3 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .e3 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 16px;
  height: 16px;
  background-color: #ffa345;
}

#table #table-base .table .row .e3 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .e3 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .e3 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -16px;
  transform: translateX(-50%);
  z-index: 40;
}

#table #table-base .table .row .e3 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .e3 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .e3 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 15px;
  height: 15px;
  background-color: #afda6e;
}

#table #table-base .table .row .e3 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .e3 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .f3 .carbs {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: -12px;
  transform: translateX(-50%);
  z-index: 10;
}

#table #table-base .table .row .f3 .carbs:hover {
  z-index: 60;
}

#table #table-base .table .row .f3 .carbs:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .f3 .carbs .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 20px;
  height: 20px;
  background-color: #ff595a;
}

#table #table-base .table .row .f3 .carbs .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .f3 .carbs .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .f3 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 40;
}

#table #table-base .table .row .f3 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .f3 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .f3 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 17px;
  height: 17px;
  background-color: #afda6e;
}

#table #table-base .table .row .f3 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .f3 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .f3 .fruits {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -16px;
  transform: translateX(-50%);
  z-index: 30;
}

#table #table-base .table .row .f3 .fruits:hover {
  z-index: 60;
}

#table #table-base .table .row .f3 .fruits:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .f3 .fruits .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 15px;
  height: 15px;
  background-color: #ffa345;
}

#table #table-base .table .row .f3 .fruits .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .f3 .fruits .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .a4 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 40;
}

#table #table-base .table .row .a4 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .a4 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .a4 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 12px;
  height: 12px;
  background-color: #afda6e;
}

#table #table-base .table .row .a4 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .a4 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .a4 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -10px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .a4 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .a4 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .a4 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 10px;
  height: 10px;
  background-color: #ffd655;
}

#table #table-base .table .row .a4 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .a4 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .b4 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -5px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .b4 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .b4 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .b4 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 11px;
  height: 11px;
  background-color: #ffd655;
}

#table #table-base .table .row .b4 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .b4 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .c4 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: 4px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .c4 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .c4 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .c4 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 13px;
  height: 13px;
  background-color: #ffd655;
}

#table #table-base .table .row .c4 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .c4 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .e4 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -5px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .e4 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .e4 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .e4 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 11px;
  height: 11px;
  background-color: #ffd655;
}

#table #table-base .table .row .e4 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .e4 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .f4 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -7px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .f4 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .f4 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .f4 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 11px;
  height: 11px;
  background-color: #ffd655;
}

#table #table-base .table .row .f4 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .f4 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .g4 .veggies {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 40;
}

#table #table-base .table .row .g4 .veggies:hover {
  z-index: 60;
}

#table #table-base .table .row .g4 .veggies:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .g4 .veggies .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 12px;
  height: 12px;
  background-color: #afda6e;
}

#table #table-base .table .row .g4 .veggies .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .g4 .veggies .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .g4 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -5px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .g4 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .g4 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .g4 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 11px;
  height: 11px;
  background-color: #ffd655;
}

#table #table-base .table .row .g4 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .g4 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .row .d5 .proteins {
  position: absolute;
  left: 50%;
  width: 30px;
  height: 30px;
  bottom: -15px;
  transform: translateX(-50%);
  z-index: 20;
}

#table #table-base .table .row .d5 .proteins:hover {
  z-index: 60;
}

#table #table-base .table .row .d5 .proteins:hover .dot {
  width: 60px;
  height: 60px;
}

#table #table-base .table .row .d5 .proteins .dot {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s ease;
  width: 8px;
  height: 8px;
  background-color: #ffd655;
}

#table #table-base .table .row .d5 .proteins .dot .value {
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0.045em;
  opacity: 0;
  transition: 0.3s ease;
  line-height: 60px;
}

#table #table-base .table .row .d5 .proteins .dot:hover .value {
  opacity: 1;
  line-height: 60px;
}

#table #table-base .table .no-border {
  border: 0;
}

#weight-loss {
  position: relative;
  width: 100%;
  height: 260px;
}

#weight-loss .shelf1 {
  position: absolute;
  bottom: 0;
  left: 20px;
}

#weight-loss #weights {
  position: relative;
  width: 440px;
  height: 200px;
  margin: 0 auto;
}

#weight-loss #weights .weight-base {
  position: relative;
  width: 170px;
  height: 100%;
  float: left;
}

#weight-loss #weights .weight-base .weight {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

#weight-loss #weights .weight-base .shadow {
  position: absolute;
  bottom: 4px;
  left: 50%;
  transform: translateX(-50%);
  width: 150px;
  height: 5px;
  background-color: #cce0df;
  border-radius: 3px;
  z-index: 1;
}

#weight-loss #weights .weight-base .value {
  position: absolute;
  bottom: 44px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: #f1efea;
  letter-spacing: 0.025em;
  z-index: 3;
}

#weight-loss #weights .weight-base .value .kg {
  font-size: 10px;
  margin-left: 4px;
}

#weight-loss #weights .weight-base .star1 {
  position: absolute;
  top: 90px;
  right: 10px;
  z-index: 4;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

#weight-loss #weights .weight-base .star2 {
  position: absolute;
  top: 70px;
  left: -6px;
  z-index: 4;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.3s;
}

#weight-loss #weights .weight-base .star3 {
  position: absolute;
  top: 44px;
  left: 63px;
  z-index: 4;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.3s;
}

#weight-loss #weights .weight-base .star4 {
  position: absolute;
  top: 16px;
  right: 6px;
  z-index: 4;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-delay: 0.6s;
}

#weight-loss #weights .weight-base .star5 {
  position: absolute;
  top: 4px;
  right: 58px;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

#weight-loss #weights .weight-base .star6 {
  position: absolute;
  top: -16px;
  left: 32px;
  z-index: 4;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}

#weight-loss #weights .arrow-to {
  position: relative;
  height: 100%;
  margin: 0 20px;
  float: left;
  min-width: 60px;
}

#weight-loss #weights .arrow-to .arrow {
  position: absolute;
  bottom: 60px;
  left: 0;
  min-width: 60px;
  opacity: 0;
}

#fridge-bottom {
  position: relative;
  width: 960px;
  height: 300px;
  margin: 0 auto;
  margin-top: -60px;
}

#fridge-bottom .shadow {
  position: absolute;
  bottom: 113px;
  left: 50%;
  transform: translateX(-50%);
  width: 1100px;
  height: 16px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

#fridge-bottom .pizza-arm {
  position: absolute;
  top: 92px;
  left: -88px;
  transform-origin: 38px 7px;
  animation-name: pizza-wave;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

#fridge-bottom .pizza-waving {
  position: absolute;
  top: 0;
  left: -105px;
}

#fridge-bottom .leg-left {
  position: absolute;
  top: 0;
  left: 0;
}

#fridge-bottom .leg-right {
  position: absolute;
  top: 0;
  right: 0;
}
