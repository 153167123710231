$carbs: #ff595a;
$proteins: #ffd655;
$fruits: #ffa345;
$veggies: #afda6e;
$drinks: #8ed8f6;

@mixin field {
    position: absolute;
    left: 50%;
    width: 30px;
    height: 30px;
    &:hover {
        z-index: 60;
    }
    &:hover .dot {
        width: 60px;
        height: 60px;
    }
}

@mixin dot {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
    transition: 0.3s ease;
    .value {
        width: 100%;
        height: 100%;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-size: 12px;
        color: #ffffff;
        letter-spacing: 0.045em;
        opacity: 0;
        transition: 0.3s ease;
        line-height: 60px;
    }
    &:hover .value {
        opacity: 1;
        line-height: 60px;
    }
}

.a1 {
    position: relative;
    .fruits {
        @include field;
        top: 3px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 29px;
            height: 29px;
            background-color: $fruits;
        }
    }
}
.b1 {
    position: relative;
    .fruits {
        @include field;
        top: 3px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 29px;
            height: 29px;
            background-color: $fruits;
        }
    }
    .drinks {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 25px;
            height: 25px;
            background-color: $drinks;
        }
    }
}
.c1 {
    position: relative;
    .fruits {
        @include field;
        bottom: 3px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 26px;
            height: 26px;
            background-color: $fruits;
        }
    }
    .carbs {
        @include field;
        top: 5px;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 28px;
            height: 28px;
            background-color: $carbs;
        }
    }
}
.d1 {
    position: relative;
    .fruits {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 25px;
            height: 25px;
            background-color: $fruits;
        }
    }
}
.e1 {
    position: relative;
    .drinks {
        @include field;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 27px;
            height: 27px;
            background-color: $drinks;
        }
    }
}
.g1 {
    position: relative;
    .drinks {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 25px;
            height: 25px;
            background-color: $drinks;
        }
    }
}
.a2 {
    .drinks {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 20px;
            height: 20px;
            background-color: $drinks;
        }
    }
}
.c2 {
    .drinks {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 20px;
            height: 20px;
            background-color: $drinks;
        }
    }
    .veggies {
        @include field;
        top: 6px;
        transform: translateX(-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 23px;
            height: 23px;
            background-color: $veggies;
        }
    }
}
.e2 {
    .carbs {
        @include field;
        top: -3px;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 24px;
            height: 24px;
            background-color: $carbs;
        }
    }
}
.f2 {
    .drinks {
        @include field;
        top: -13px;
        transform: translateX(-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 24px;
            height: 24px;
            background-color: $drinks;
        }
    }
}
.g2 {
    .carbs {
        @include field;
        top: 0;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 24px;
            height: 24px;
            background-color: $carbs;
        }
    }
    .fruits {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 20px;
            height: 20px;
            background-color: $fruits;
        }
    }
}
.a3 {
    .carbs {
        @include field;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 16px;
            height: 16px;
            background-color: $carbs;
        }
    }
}
.b3 {
    .carbs {
        @include field;
        top: 6px;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 18px;
            height: 18px;
            background-color: $carbs;
        }
    }
    .veggies {
        @include field;
        top: -2px;
        transform: translateX(-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 18px;
            height: 18px;
            background-color: $veggies;
        }
    }
}
.d3 {
    .carbs {
        @include field;
        top: 8px;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 18px;
            height: 18px;
            background-color: $carbs;
        }
    }
    .drinks {
        @include field;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 50;
        .dot {
            @include dot;
            width: 18px;
            height: 18px;
            background-color: $drinks;
        }
    }
    .veggies {
        @include field;
        bottom: -16px;
        transform: translateX(-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 15px;
            height: 15px;
            background-color: $veggies;
        }
    }
}
.e3 {
    .fruits {
        @include field;
        bottom: -6px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 16px;
            height: 16px;
            background-color: $fruits;
        }
    }
    .veggies {
        @include field;
        bottom: -16px;
        transform: translateX(-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 15px;
            height: 15px;
            background-color: $veggies;
        }
    }
}
.f3 {
    .carbs {
        @include field;
        top: -12px;
        transform: translateX(-50%);
        z-index: 10;
        .dot {
            @include dot;
            width: 20px;
            height: 20px;
            background-color: $carbs;
        }
    }
    .veggies {
        @include field;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 17px;
            height: 17px;
            background-color: $veggies;
        }
    }
    .fruits {
        @include field;
        bottom: -16px;
        transform: translateX(-50%);
        z-index: 30;
        .dot {
            @include dot;
            width: 15px;
            height: 15px;
            background-color: $fruits;
        }
    }
}
.a4 {
    .veggies {
        @include field;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 12px;
            height: 12px;
            background-color: $veggies;
        }
    }
    .proteins {
        @include field;
        bottom: -10px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 10px;
            height: 10px;
            background-color: $proteins;
        }
    }
}
.b4 {
    .proteins {
        @include field;
        bottom: -5px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 11px;
            height: 11px;
            background-color: $proteins;
        }
    }
}
.c4 {
    .proteins {
        @include field;
        bottom: 4px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 13px;
            height: 13px;
            background-color: $proteins;
        }
    }
}
.e4 {
    .proteins {
        @include field;
        bottom: -5px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 11px;
            height: 11px;
            background-color: $proteins;
        }
    }
}
.f4 {
    .proteins {
        @include field;
        bottom: -7px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 11px;
            height: 11px;
            background-color: $proteins;
        }
    }
}
.g4 {
    .veggies {
        @include field;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 40;
        .dot {
            @include dot;
            width: 12px;
            height: 12px;
            background-color: $veggies;
        }
    }
    .proteins {
        @include field;
        bottom: -5px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 11px;
            height: 11px;
            background-color: $proteins;
        }
    }
}
.d5 {
    .proteins {
        @include field;
        bottom: -15px;
        transform: translateX(-50%);
        z-index: 20;
        .dot {
            @include dot;
            width: 8px;
            height: 8px;
            background-color: $proteins;
        }
    }
}

