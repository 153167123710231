@import "animations";

.group:after {
	content: "";
	display: table;
	clear: both;
}

body {
	padding: 0;
	margin: 0;
	background-color: #e74948;
}

#intro {
	position: relative;
	width: 100%;
	height: 100vh;
	#fridge-top {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 960px;
		height: 660px;
		border-top-left-radius: 120px;
		border-top-right-radius: 120px;
		background-image: url("../img/fridge-top-shadow.png");
		background-position: bottom;
		background-color: #ffffff;
		.name {
			position: absolute;
			top: -70px;
			left: 50%;
			transform: translateX(-50%);
			font-family: 'Poppins', sans-serif;
			font-weight: 500;
			font-size: 18px;
			color: #f1efea;
			letter-spacing: 0.045em;
			z-index: 2;
		}
		.indents {
			position: absolute;
			top: 70px;
			left: 50%;
			transform: translateX(-50%);
		}
		.handle {
			position: absolute;
			bottom: 50px;
			left: 60px;
		}
		.info {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			text-align: center;

			.title {
				position: relative;
				font-family: 'Poppins', sans-serif;
				font-weight: 500;
				font-size: 30px;
				color: #4a4a4a;
				letter-spacing: 0.045em;
				margin-bottom: 10px;
			}
			.text {
				position: relative;
				max-width: 350px;
				margin: 0 auto;
				font-family: 'Poppins', sans-serif;
				font-weight: 500;
				font-size: 16px;
				color: #4a4a4a;
				letter-spacing: 0.045em;
			}
		}
		.button-go {
			position: absolute;
			bottom: 50px;
			left: 50%;
			transform: translateX(-50%);
			width: auto;
			height: 44px;
			padding: 0 35px;
			background-color: #ff555b;
			border-radius: 3px;
			line-height: 44px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0.045em;
			cursor: pointer;
		}
	}
}

@media screen and (max-height: 720px) {
	#fridge-top {
		height: 560px !important;
	}
}

#fridge-middle {
	position: relative;
	width: 100%;
}

#fridge-inside {
	position: relative;
	width: 960px;
	margin: 0 auto;
	background-color: #e6e2d0;
	padding: 60px 0;
	border-bottom-left-radius: 60px;
	border-bottom-right-radius: 60px;

	.top-shadow {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 30px;
	}
	.outer-shadow {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-image: url("../img/fridge-outer-shadow.png");
		background-position: bottom;
		.bottom {
			position: absolute;
			bottom: -4px;
			left: 0;
			z-index: 2;
		}
	}
}

#inside-big {
	position: relative;
	margin: 0 auto;
	padding: 120px 0;
	width: 840px;
	background-color: #f7f5e6;
	border-radius: 30px;
	box-shadow: inset 0px 0px 50px 0px rgba(240,237,213,1);

	.inside-small {
		position: absolute;
		top: 80px;
		left: 80px;
		width: 680px;
		height: calc( 100% - 160px );
		border-radius: 30px;
		background-color: #f7f6ed;
		text-align: center;
	}
}

.title-big {
	position: relative;
	margin: 0 auto;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 48px;
	color: #4a4a4a;
	letter-spacing: 0.045em;
	text-align: center;
	margin: 120px 0;
}

.no-margin-top {
	margin-top: 0;
}
.no-margin-bottom {
	margin-bottom: 0;
}

.tnx-margin {
	margin-top: 160px;
	margin-bottom: 60px;
}

#shelf-team1 {
	position: relative;
	width: 100%;
	height: 300px;
	margin-bottom: 70px;

	.shelf1 {
		position: absolute;
		bottom: 0;
		left: 20px;
	}
	.teammate1 {
		position: absolute;
		bottom: 44px;
		left: 100px;
		width: 160px;
		height: 262px;
		.shadow {
			position: absolute;
			bottom: 4px;
			left: 50%;
			transform: translateX(-50%);
			width: 54px;
			height: 5px;
			background-color: #cce0df;
			border-radius: 3px;
			z-index: 1;
		}
		.pizza {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
			cursor: pointer;
			&:hover + .label {
				opacity: 1;
			}
		}
		.pizza-arm {
			position: absolute;
			bottom: 87px;
			left: 12px;
			transform-origin: 33px 22px;
			animation-name: pizza-wave;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
		}
		.label {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 44px;
			padding: 0 20px;
			background-color: #ff595a;
			border-radius: 3px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0.045em;
			line-height: 44px;
			opacity: 0;
			transition: 0.3s ease-in-out;
			&::after {
				content: '';
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				width: 0; 
				height: 0; 
				border-left: 8px solid transparent;  /* left arrow slant */
				border-right: 8px solid transparent; /* right arrow slant */
				border-top: 10px solid #ff595a; /* bottom, add background color here */
				font-size: 0;
				line-height: 0;
			}
		}
	}
	.teammate2 {
		position: absolute;
		bottom: 44px;
		right: 300px;
		width: 154px;
		height: 262px;
		&:hover .label {
			opacity: 1;
		}
		.shadow {
			position: absolute;
			bottom: 4px;
			left: 50%;
			transform: translateX(-50%);
			width: 130px;
			height: 5px;
			background-color: #cce0df;
			border-radius: 3px;
			z-index: 1;
		}
		.egg {
			position: absolute;
			bottom: 0;
			left: 20px;
			z-index: 2;
			cursor: pointer;
			&:hover + .label {
				opacity: 1;
			}
		}
		.egg-arm-left {
			position: absolute;
			bottom: 60px;
			left: 0;
			transform-origin: 33px 22px;
			animation-name: egg-left-wave;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
		}
		.egg-arm-right {
			position: absolute;
			bottom: 57px;
			right: 0;
			transform-origin: 2px 28px;
			animation-name: egg-right-wave;
			animation-duration: 1s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			z-index: 3;
		}
		.egg-tear {
			position: absolute;
			bottom: 62px;
			right: 64px;
			animation-name: egg-tear;
			animation-duration: 1.6s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			z-index: 3;
		}
		.label {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 44px;
			padding: 0 20px;
			background-color: #ffd655;
			border-radius: 3px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0.045em;
			line-height: 44px;
			opacity: 0;
			transition: 0.3s ease-in-out;
			&::after {
				content: '';
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				width: 0; 
				height: 0; 
				border-left: 8px solid transparent;  /* left arrow slant */
				border-right: 8px solid transparent; /* right arrow slant */
				border-top: 10px solid #ffd655; /* bottom, add background color here */
				font-size: 0;
				line-height: 0;
			}
		}
	}
	.teammate3 {
		position: absolute;
		bottom: 44px;
		right: -48px;
		width: 188px;
		height: 262px;
		.shadow {
			position: absolute;
			bottom: 4px;
			left: 24px;
			width: 90px;
			height: 5px;
			background-color: #cce0df;
			border-radius: 3px;
			z-index: 1;
			animation-name: orange-shadow;
			animation-duration: 2.5s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
		}
		.orange {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 2;
			cursor: pointer;
			animation-name: orange-roll;
			animation-duration: 2.5s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			cursor: pointer;
			&:hover + .label {
				opacity: 1;
			}
		}
		.label {
			position: absolute;
			top: 0;
			left: calc( 50% - 50px );
			transform: translateX(-50%);
			height: 44px;
			padding: 0 30px;
			background-color: #ffa345;
			border-radius: 3px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0.045em;
			line-height: 44px;
			opacity: 0;
			transition: 0.3s ease-in-out;
			&::after {
				content: '';
				position: absolute;
				bottom: -10px;
				right: 12px;
				width: 0; 
				height: 0; 
				border-left: 8px solid transparent;  /* left arrow slant */
				border-right: 8px solid transparent; /* right arrow slant */
				border-top: 10px solid #ffa345; /* bottom, add background color here */
				font-size: 0;
				line-height: 0;
			}
		}
	}
}

#shelf-team2 {
	position: relative;
	width: 100%;
	height: 300px;

	.shelf1 {
		position: absolute;
		bottom: 0;
		left: 20px;
	}
	.teammate1 {
		position: absolute;
		bottom: 44px;
		left: 100px;
		width: 204px;
		height: 285px;
		.shadow {
			position: absolute;
			bottom: 4px;
			left: 50%;
			transform: translateX(-50%);
			width: 200px;
			height: 5px;
			background-color: #cce0df;
			border-radius: 3px;
			z-index: 1;
		}
		.cabbage {
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: 2;
			cursor: pointer;
			&:hover + .label {
				opacity: 1;
			}
		}
		.label {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 44px;
			padding: 0 20px;
			background-color: #afda6e;
			border-radius: 3px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0.045em;
			line-height: 44px;
			opacity: 0;
			transition: 0.3s ease-in-out;
			&::after {
				content: '';
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				width: 0; 
				height: 0; 
				border-left: 8px solid transparent;  /* left arrow slant */
				border-right: 8px solid transparent; /* right arrow slant */
				border-top: 10px solid #afda6e; /* bottom, add background color here */
				font-size: 0;
				line-height: 0;
			}
		}
	}
	.teammate2 {
		position: absolute;
		bottom: 44px;
		right: 40px;
		width: 432px;
		height: 285px;
		&:hover .label {
			opacity: 1;
		}
		.shadow {
			position: absolute;
			bottom: 4px;
			left: 0;
			width: 420px;
			height: 5px;
			background-color: #cce0df;
			border-radius: 3px;
			z-index: 1;
		}
		.bottle {
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 3;
			cursor: pointer;
			&:hover + .label {
				opacity: 1;
			}
		}
		.bottle-leg-left {
			position: absolute;
			bottom: 41px;
			right: 0;
			transform-origin: 2px 12px;
			animation-name: bottle-leg-left;
			animation-duration: 3s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
			z-index: 3;
		}
		.bottle-leg-right {
			position: absolute;
			bottom: 56px;
			right: 0;
			transform-origin: 1px 12px;
			animation-name: bottle-leg-right;
			animation-duration: 3s;
			animation-timing-function: ease-in-out;
			animation-iteration-count: infinite;
		}
		.cup {
			position: absolute;
			bottom: 15px;
			right: 62px;
			z-index: 2;
			cursor: pointer;
			&:hover + .label {
				opacity: 1;
			}
		}
		.label {
			position: absolute;
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			height: 44px;
			padding: 0 20px;
			background-color: #8ed8f6;
			border-radius: 3px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
			letter-spacing: 0.045em;
			line-height: 44px;
			opacity: 0;
			transition: 0.3s ease-in-out;
			&::after {
				content: '';
				position: absolute;
				bottom: -10px;
				left: 50%;
				transform: translateX(-50%);
				width: 0; 
				height: 0; 
				border-left: 8px solid transparent;  /* left arrow slant */
				border-right: 8px solid transparent; /* right arrow slant */
				border-top: 10px solid #8ed8f6; /* bottom, add background color here */
				font-size: 0;
				line-height: 0;
			}
		}
	}
}

.title-small {
	position: relative;
	margin: 0 auto;
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: 18px;
	color: #4a4a4a;
	letter-spacing: 0.025em;
	text-align: center;
	margin-bottom: 100px;
}

.shelf-bowls {
	position: relative;
	width: 100%;
	height: 180px;
	margin-bottom: 90px;

	.shelf1 {
		position: absolute;
		bottom: 0;
		left: 20px;
	}
	.bowls-container {
		position: absolute;
		bottom: 44px;
		left: 30px;
		width: 780px;
		height: 150px;
		padding: 0 10px;

		.bowl-element {
			position: relative;
			width: 170px;
			height: 100%;
			margin: 0 10px;
			float: left;
			.bowl {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 2;
				opacity: 0;
			}
			.shadow {
				position: absolute;
				bottom: 4px;
				left: 50%;
				transform: translateX(-50%);
				width: 0;
				height: 5px;
				background-color: #cce0df;
				border-radius: 3px;
				z-index: 1;
				opacity: 0;
			}
			.count {
				position: absolute;
				bottom: 28px;
				left: 50%;
				transform: translateX(-50%);
				text-align: center;
				font-family: 'Poppins', sans-serif;
				font-weight: 500;
				font-size: 20px;
				color: #4a4a4a;
				letter-spacing: 0.025em;
				z-index: 3;
			}
			.kcal {
				position: absolute;
				bottom: 4px;
				left: 50%;
				transform: translateX(-50%);
				width: 60px;
				height: 60px;
				background-color: #afda6e;
				border-radius: 50%;
				text-align: center;
				font-family: 'Poppins', sans-serif;
				font-weight: 400;
				font-size: 14px;
				color: #ffffff;
				letter-spacing: 0.045em;
				line-height: 60px;
				z-index: 2;
				opacity: 0;
			}
			.kcal-shadow {
				position: absolute;
				bottom: 4px;
				left: 50%;
				transform: translateX(-50%);
				width: 50px;
				height: 5px;
				background-color: #cce0df;
				border-radius: 3px;
				z-index: 1;
				opacity: 0;
			}
		}
	}
}

.margin-bottom-140 {
	margin-bottom: 140px;
}

#table {
	position: relative;
	width: 100%;
	height: 600px;
	margin-bottom: 140px;

	.shelf-top {
		position: absolute;
		top: 0;
		left: 20px;
		width: 800px;
		height: 144px;
		z-index: 10;
		.shelf1 {
			position: absolute;
			bottom: 0;
			max-height: 94px;
		}
		.nutrients {
			position: absolute;
			bottom: 44px;
			left: 50%;
			transform: translateX(-50%);
			min-width: 600px;
			.nutrient {
				position: relative;
				width: 100px;
				height: 100px;
				margin: 0 10px;
				float: left;
				.shadow {
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
					width: 80px;
					height: 5px;
					background-color: #cce0df;
					border-radius: 3px;
					z-index: 1;
					opacity: 0;
				}
				.element {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 100%;
					height: 100%;
					border-radius: 50%;
					text-align: center;
					font-family: 'Poppins', sans-serif;
					font-weight: 400;
					font-size: 14px;
					color: #ffffff;
					letter-spacing: 0.045em;
					line-height: 100px;
					z-index: 2;
				}
				.carbs {
					background-color: #ff595a;
					transform: translate(-50%,-50%) scale(0);
					opacity: 0;
				}
				.proteins {
					background-color: #ffd655;
					transform: translate(-50%,-50%) scale(0);
					opacity: 0;
				}
				.fruits {
					background-color: #ffa345;
					transform: translate(-50%,-50%) scale(0);
					opacity: 0;
				}
				.veggies {
					background-color: #afda6e;
					transform: translate(-50%,-50%) scale(0);
					opacity: 0;
				}
				.drinks {
					background-color: #8ed8f6;
					transform: translate(-50%,-50%) scale(0);
					opacity: 0;
				}
			}
		}
	}
	.shelf-bottom {
		position: absolute;
		bottom: 0;
		left: 20px;
		width: 800px;
		max-height: 94px;
		z-index: 1;
	}
	.pole-left {
		position: absolute;
		bottom: 44px;
		left: 70px;
	}
	.pole-right {
		position: absolute;
		bottom: 44px;
		right: 70px;
	}
	.days {
		position: absolute;
		bottom: 42px;
		left: 50%;
		transform: translateX(-50%);
		min-width: 600px;
		.day {
			height: 20px;
			text-align: center;
			font-family: 'Poppins', sans-serif;
			font-weight: 500;
			font-size: 14px;
			color: #bccfce;
			letter-spacing: 0.045em;
			line-height: 20px;
			float: left;
		}
		.mon {
			width: 90px;
		}
		.tue {
			width: 70px;
		}
		.wed {
			width: 100px;
		}
		.thu {
			width: 70px;
		}
		.fri {
			width: 100px;
		}
		.sat {
			width: 80px;
		}
		.sun {
			width: 90px;
		}
	}

	#table-base {
		position: absolute;
		top: 144px;
		left: -60px;
		width: 960px;
		height: 360px;
		.grams {
			position: relative;
			width: 130px;
			height: 100%;
			float: left;
			.title {
				position: relative;
				width: 60px;
				height: 100%;
				float: left;
				.text {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%) rotate(-90deg);
					text-align: center;
					font-family: 'Poppins', sans-serif;
					font-weight: 500;
					font-size: 14px;
					color: #bccfce;
					letter-spacing: 0.045em;
				}
			}
			.marks {
				position: relative;
				width: 70px;
				height: 100%;
				padding: 30px 0;
				float: left;
				ul {
					width: 100%;
					margin: 0;
					padding: 0;
					li {
						width: 100%;
						height: 60px;
						text-align: center;
						font-family: 'Poppins', sans-serif;
						font-weight: 500;
						font-size: 14px;
						color: #bccfce;
						letter-spacing: 0.045em;
						line-height: 60px;
						list-style: none;
					}
				}
			}
		}
		.liters {
			position: relative;
			width: 130px;
			height: 100%;
			float: left;
			.title {
				position: relative;
				width: 60px;
				height: 100%;
				float: left;
				.text {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%) rotate(90deg);
					text-align: center;
					font-family: 'Poppins', sans-serif;
					font-weight: 500;
					font-size: 14px;
					color: #ffffff;
					letter-spacing: 0.045em;
				}
			}
			.marks {
				position: relative;
				width: 70px;
				height: 100%;
				padding: 30px 0;
				float: left;
				ul {
					width: 100%;
					margin: 0;
					padding: 0;
					li {
						width: 100%;
						height: 60px;
						text-align: center;
						font-family: 'Poppins', sans-serif;
						font-weight: 500;
						font-size: 14px;
						color: #bccfce;
						letter-spacing: 0.045em;
						line-height: 60px;
						list-style: none;
					}
				}
			}
		}
		.table {
			position: relative;
			width: 668px;
			height: 100%;
			margin: 0 16px;
			float: left;
			.row {
				position: relative;
				width: calc( 100% - 80px );
				height: 16.3%;
				border-bottom: 2px solid #dfeeea;
				padding: 0 40px;
				.field {
					position: relative;
					width: 84px;
					height: 100%;
					float: left;
					.line-ver {
						position: absolute;
						top: 0;
						left: 50%;
						transform: translateX(-50%);
						width: 2px;
						height: 100%;
						background-color: #dfeeea;
					}
				}

@import "table";

			}
			.no-border {
				border: 0;
			}
		}
	}
}

#weight-loss {
	position: relative;
	width: 100%;
	height: 260px;

	.shelf1 {
		position: absolute;
		bottom: 0;
		left: 20px;
	}
	#weights {
		position: relative;
		width: 440px;
		height: 200px;
		margin: 0 auto;
		.weight-base {
			position: relative;
			width: 170px;
			height: 100%;
			float: left;
			.weight {
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				z-index: 2;
			}
			.shadow {
				position: absolute;
				bottom: 4px;
				left: 50%;
				transform: translateX(-50%);
				width: 150px;
				height: 5px;
				background-color: #cce0df;
				border-radius: 3px;
				z-index: 1;
			}
			.value {
				position: absolute;
				bottom: 44px;
				left: 50%;
				transform: translateX(-50%);
				text-align: center;
				font-family: 'Poppins', sans-serif;
				font-weight: 500;
				font-size: 30px;
				color: #f1efea;
				letter-spacing: 0.025em;
				z-index: 3;
				.kg {
					font-size: 10px;
					margin-left: 4px;
				}
			}
			.star1 {
				position: absolute;
				top: 90px;
				right: 10px;
				z-index: 4;
				animation-name: pulse;
				animation-duration: 1.5s;	
				animation-iteration-count: infinite;
			}
			.star2 {
				position: absolute;
				top: 70px;
				left: -6px;
				z-index: 4;
				animation-name: pulse;
				animation-duration: 1.5s;	
				animation-iteration-count: infinite;
				animation-delay: 0.3s;
			}
			.star3 {
				position: absolute;
				top: 44px;
				left: 63px;
				z-index: 4;
				animation-name: pulse;
				animation-duration: 1.5s;	
				animation-iteration-count: infinite;
				animation-delay: 0.3s;
			}
			.star4 {
				position: absolute;
				top: 16px;
				right: 6px;
				z-index: 4;
				animation-name: pulse;
				animation-duration: 1.5s;	
				animation-iteration-count: infinite;
				animation-delay: 0.6s;
			}
			.star5 {
				position: absolute;
				top: 4px;
				right: 58px;
				animation-name: pulse;
				animation-duration: 1.5s;	
				animation-iteration-count: infinite;
			}
			.star6 {
				position: absolute;
				top: -16px;
				left: 32px;
				z-index: 4;
				animation-name: pulse;
				animation-duration: 1.5s;	
				animation-iteration-count: infinite;
			}
		}
		.arrow-to {
			position: relative;
			height: 100%;
			margin: 0 20px;
			float: left;
			min-width: 60px;
			.arrow {
				position: absolute;
				bottom: 60px;
				left: 0;
				min-width: 60px;
				opacity: 0;
			}
		}
	}
}

#fridge-bottom {
	position: relative;
	width: 960px;
	height: 300px;
	margin: 0 auto;
	margin-top: -60px;
	.shadow {
		position: absolute;
		bottom: 113px;
		left: 50%;
		transform: translateX(-50%);
		width: 1100px;
		height: 16px;
		background-color: rgba(0,0,0,0.12);
		border-radius: 8px;
	}
	.pizza-arm {
		position: absolute;
		top: 92px;
		left: -88px;
		transform-origin: 38px 7px;
		animation-name: pizza-wave;
		animation-duration: 1s;
		animation-timing-function: ease-in-out;
		animation-iteration-count: infinite;
	}
	.pizza-waving {
		position: absolute;
		top: 0;
		left: -105px;
	}
	.leg-left {
		position: absolute;
		top: 0;
		left: 0;
	}
	.leg-right {
		position: absolute;
		top: 0;
		right: 0;
	}
}
